import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import {
  ArrowRightICon,
  MobNextArrowIcon
} from "../../components/common/Icons";
import { useMedia } from "use-media";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import Cards from "../../components/chat-channel/Cards";
import useInterval from "../../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";
import KeyResources from "../../components/common/KeyResources";

export const blogImg = require("../../assets/images/landing_page/digital.png");
export const whitepaperImg = require("../../assets/img/key-resources/ci_1_1.png");
export const videoImg = require("../../assets/img/key-resources/bc_1_2.png");

const servicenow = require("../../assets/images/icons-web/servicenow.svg");
const freshservice = require("../../assets/images/icons-web/freshservice.svg");
const jira = require("../../assets/images/icons-web/jira.svg");
const solarwinds = require("../../assets/images/icons-web/solarwinds.svg");
const service_desk = require("../../assets/images/icons-web/service_desk.svg");
const jumpcloud = require("../../assets/images/icons-web/jumpcloud.svg");
const ad = require("../../assets/images/icons-web/zure.svg");
const office365 = require("../../assets/images/icons-web/ms_office.svg");
const okta = require("../../assets/images/icons-web/okta.svg");
const onelogin = require("../../assets/images/icons-web/onelogin.svg");
const bamboo = require("../../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../../assets/images/icons-web/gusto.svg");
const sap = require("../../assets/images/icons-web/sap_successfactors.svg");
const intune = require("../../assets/images/icons-web/ms_intune.svg");
const mobileiron = require("../../assets/images/icons-web/mobile_iron.svg");
const hexnode = require("../../assets/images/icons-web/hexnode.svg");
const citrix = require("../../assets/images/icons-web/citrix.svg");

const mobile = require("../../assets/images/landing_page/customers_mob.png");

const topImage = require("../../assets/images/landing_page/header_img.png");

const section_two = require("../../assets/images/landing_page/it_helpdesk_chatbot.png");
const section_three = require("../../assets/images/landing_page/ai_img_2.png");
const section_four = require("../../assets/images/landing_page/ai_img_3.png");

const noCoding = require("../../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../../assets/images/landing_page/bottom_icon_3.png");

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation
  },
  {
    header: "Secure hybrid app workflows",
    content: `Workativ enables combining your on-prem apps with cloud apps through its  <span>
    <a href='/conversational-ai-platform/on-prem-app-connector'>secure connector</a>
  </span> so you can create digital workflows to automate tasks without a hitch, be it on-prem or cloud.`,
    image: boostIT
  }
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
const headerMenu = [
  {
    menu: "What is AI Self-Service",
    href: "#chatbot",
    id: "chatbot",
    offset: 400
  },
  {
    menu: "AI Chatbot",
    href: "#marketplace",
    id: "marketplace",
    tabFor: "section1",
    offset: 150
  },
  {
    menu: "AI Self Service Workflow",
    href: "#marketplace",
    id: "marketplace",
    tabFor: "section2",
    offset: 150
  },
  {
    menu: "Slack & MS Teams",
    href: "#marketplace",
    id: "marketplace",
    tabFor: "section3",
    offset: 150
  },
  {
    menu: "App Integrations",
    href: "#integrations",
    id: "integrations",
    offset: 150
  }
];

const OpenMenu = () => {
  const [isActive, setIsActive] = React.useState("What is AI Self-Service");

  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);
  return (
    <>
      <a
        className={
          !showMenu
            ? "navbar-brand js-scroll-trigger"
            : "navbar-brand js-scroll-trigger icon_open"
        }
        href="/"
      >
        <img
          src={require("../../assets/images/workativ-logo.png")}
          alt="Workativ"
        />
      </a>
      <span>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className={
              !showMenu
                ? "navbar-toggler-icon"
                : "navbar-toggler-icon icon_open"
            }
            onClick={onClick}
          >
            {!showMenu ? (
              <svg viewBox="0 -53 384 384">
                <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"></path>
              </svg>
            ) : (
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path>
              </svg>
            )}
          </span>
        </button>
      </span>
      {showMenu ? (
        <div id="results" className="search-results">
          <div className="navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {headerMenu.map((menu, i) => (
                <li className="nav-item" onClick={onClick}>
                  i
                  {/* <AnchorLink
                    offset={() => 160}
                    href={menu.href}
                    className={
                      isActive === menu.menu
                        ? "url_manipulation nav-link js-scroll-trigger active"
                        : "url_manipulation nav-link js-scroll-trigger "
                    }
                    onClick={() => {
                      setIsActive(menu.menu);
                    }}
                  >
                    {" "}
                    {menu.tabFor ? (
                      <Tab tabFor={menu.tabFor}>{menu.menu} Hello</Tab>
                    ) : (
                      menu.menu + "2"
                    )}
                  </AnchorLink> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default function FeatureHomePage() {
  const [isActive, setIsActive] = React.useState("What is AI Self-Service");

  const slides = [
    {
      image: <Section1 />,
      title: "Conversational AI Chatbots"
    },
    {
      image: <Section2 />,
      title: "AI Self Service workflow automation"
    },
    {
      image: <Section3 />,
      title: "AI Self Service on your Slack and Teams"
    }
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 50000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("What is AI Self-Service");

  const listenScrollEvent = e => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  // console.log("offset", offset)

  // console.log("FeatureHomePage", offset)

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free AI Self-Service Chatbot| Workativ Assistant Chatbot"
        description="Use AI Self Service Chatbot to automate workplace support. Build ai based self-service helpdesk using chatbot easily in minutes. Reinvent your workplace HR &amp; IT support instantly."
        keywords={[
          "WHR chatbot ",
          "AI Chatbot",
          "IT Helpdesk chatbot",
          "Automation"
        ]}
        ogImage={topImage}
        ogTitle="Free AI Self-Service Chatbot| Workativ Assistant Chatbot"
        ogDescription="Use AI Self Service Chatbot to automate workplace support. Build ai based self-service helpdesk using chatbot easily in minutes. Reinvent your workplace HR &amp; IT support instantly. "
      />
      <section className="ai-self-service-chatbot it_support_chatbot">
        <TabProvider defaultTab="section1" vertical>
          <Container>
            <Layout backgroundColor={"landing_bg"} logoFor="ASSISTANT">
              <RegisterSection
                rightImage={topImage}
                backgroundColor={"landing_bg"}
                altImage={"What is AI Self-Service"}
              >
                <RegisterSection.FormWithContent>
                  <RegisterSection.MainHeader>
                    Empower your employees with AI Self Service using advanced
                    NLP and workflow automation
                  </RegisterSection.MainHeader>
                  <RegisterSection.SubHeader>
                    Workativ helps you automate most of your employee enquires
                    and service requests using AI Self Service with in-built
                    workflow automation technology.
                  </RegisterSection.SubHeader>
                </RegisterSection.FormWithContent>
              </RegisterSection>

              <section
                className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_ai workflow_white"
                id="chatbot"
              >
                <div className="container">
                  <div className="row">
                    <h3 className="header_content">What is AI Self-Service?</h3>
                    <div className="col-md-6 feature_page_content_left">
                      <p>
                        {" "}
                        AI Self Service in the context of workplace support
                        refers to the next generation of technologies available
                        to deliver modern end user support autonomously. An AI
                        based self-service approach allows users to quickly
                        search for information in knowledge base or perform
                        automated tasks and workflows using Conversational AI
                        and Natural Language Processing (NLP) technologies.
                        Using conversational AI interfaces such as Chatbots or
                        Digital Assistant, users can chat with the “system” as
                        though they would speak with a live agent via chat or
                        phone except the “system” here is AI. AI Self Service in
                        workplace support enables end users to perform several
                        workplace IT and HR related tasks such as changing
                        passwords, unlock account, onboarding users, get access
                        to applications, search documents without ever calling
                        IT Help Desk.{" "}
                      </p>
                      <p>
                        {" "}
                        AI Self Service solutions such as Chatbots allows
                        end-users to engage in a natural and transactional
                        approach via chat conversations. Make no mistake, not
                        all chatbots are same and not all usecase are similar.
                        Here are different ways AI Self Service solutions can be
                        implemented.
                      </p>
                    </div>
                    <div className="col-md-6 feature_page_content_left align_top">
                      <h3>1. Conversational AI or Chatbots:</h3>
                      <p>
                        Chatbots use Natural Language Processing (NLP) to
                        understand user chats and respond back via chat
                        conversations. E.g. end-user searching for leave policy
                        may simply ask the Chabot with “what is my leave
                        balance”.{" "}
                      </p>
                      <h3>2. Conversational Automation:</h3>
                      <p>
                        These are more popular and well suited for workplace as
                        the focus is more on tasks and workflows. Employees via
                        Conversational Automation or Tasks based Chatbots can
                        quickly resolve workplace issues and requests instantly
                        via workflow automation technology inbuilt into chatbot.
                        This delivers true end-to-end automation for companies
                        who are looking to go beyond just the conversational ai
                        capabilities.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section
                className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white "
                id="marketplace"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 mob_slider_pad_0">
                      <div className="desktop_tabs ">
                        <TabList className="horizontal_slider ai_self_horizontal_slider">
                          <Tab tabFor="section1">
                            Conversational AI Chatbots
                          </Tab>
                          <Tab tabFor="section2">
                            AI Self Service workflow automation
                          </Tab>
                          <Tab tabFor="section3">
                            AI Self Service on your Slack and Teams
                          </Tab>
                        </TabList>
                        <TabPanel tabId="section1">
                          <Section1 />
                        </TabPanel>
                        <TabPanel tabId="section2">
                          <Section2 />
                        </TabPanel>
                        <TabPanel tabId="section3">
                          <Section3 />
                        </TabPanel>
                      </div>
                      <div className="slider_wrapper">
                        <div className="slider_container" {...handlers}>
                          <div>{slides[offset].image}</div>
                        </div>
                        <div className="controls_wrapper">
                          <span
                            className="controls_left"
                            onClick={event => {
                              event.stopPropagation();
                              event.preventDefault();
                              decrementOffset();
                            }}
                          >
                            {isSmall ? (
                              <MobNextArrowIcon />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <g
                                  id="left_arrow"
                                  transform="translate(-20 -1622)"
                                >
                                  <circle
                                    id="Ellipse_254"
                                    data-name="Ellipse 254"
                                    cx="12"
                                    cy="12"
                                    r="12"
                                    transform="translate(20 1622)"
                                    fill="#fff"
                                  />
                                  <g
                                    id="Group_6414"
                                    data-name="Group 6414"
                                    transform="translate(-396 -258)"
                                  >
                                    <g
                                      id="Group_6413"
                                      data-name="Group 6413"
                                      transform="translate(424 1886)"
                                    >
                                      <path
                                        id="Path_3332"
                                        data-name="Path 3332"
                                        d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                                        transform="translate(-424 -1886)"
                                        fill="#2c7357"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            )}
                          </span>
                          <span>{slides[offset].title}</span>
                          <span
                            className="controls_right"
                            onClick={event => {
                              event.stopPropagation();
                              event.preventDefault();
                              incrementOffset();
                            }}
                          >
                            {isSmall ? (
                              <MobNextArrowIcon />
                            ) : (
                              <svg
                                id="right_arrow"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  id="Ellipse_254"
                                  data-name="Ellipse 254"
                                  cx="12"
                                  cy="12"
                                  r="12"
                                  fill="#fff"
                                />
                                <g
                                  id="Group_6414"
                                  data-name="Group 6414"
                                  transform="translate(8.861 6)"
                                >
                                  <g id="Group_6413" data-name="Group 6413">
                                    <path
                                      id="Path_3332"
                                      data-name="Path 3332"
                                      d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                                      transform="translate(-424 -1886)"
                                      fill="#2c7357"
                                    />
                                  </g>
                                </g>
                              </svg>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <AppsIntegrations />
              <section className="center_image_home cards_landing_page">
                <div className="container">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="main-slider-left">
                        <h3>Your data is yours and you control it</h3>
                        <p>
                          Workativ has implemented robust security processes and
                          controls that are in compliance with industry-leading
                          standards and regulations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <Cards cards={cardData} />
              </section>
              <KeyResources cards={resoucesData} />
              <RequestForm isFooterForm={true} />
            </Layout>
          </Container>
        </TabProvider>
      </section>
    </>
  );
}

function AppsIntegrations() {
  return (
    <section className="apps_landing_page apps_self_page" id={"integrations"}>
      <div className="container">
        <div className="apps_landing_header_wrapper">
          <div className="apps_landing_header">
            <h3>
              Connect your Self-Service with 50+ apps, 400+ actions, and 20000+
              workflow automations instantly. No coding
            </h3>
          </div>
          <div className="apps_landing_cards_section">
            {myObj.map(apps => (
              <div className="apps_landing_cards col-lg-3 col-md-6 col-12">
                <h5>{apps.header}</h5>
                <div className="apps_landing_cards_wrapper">
                  <ul>
                    {apps.userApps.map(apps => (
                      <li>
                        <img src={apps.icon} alt={apps.appName} />
                        <span>{apps.appName}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <p className="landing_content_p">
            Also available Document Management, Expense Management, Email
            Management, SMS Management, Notifications, and Collaboration Apps.
          </p>
        </div>
      </div>
    </section>
  );
}

const myObj = [
  {
    header: "13+ HR Apps",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR"
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM"
      },
      {
        icon: zoho_people,
        appName: "Zoho People"
      },
      {
        icon: gusto,
        appName: "Gusto"
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors"
      }
    ]
  },
  {
    header: "19+ ITSM Apps",
    userApps: [
      {
        icon: servicenow,
        appName: "ServiceNow"
      },
      {
        icon: freshservice,
        appName: "Freshservice"
      },
      {
        icon: jira,
        appName: "Jira Service Desk"
      },
      {
        icon: solarwinds,
        appName: "SolarWinds"
      },
      {
        icon: service_desk,
        appName: "ServiceDesk Plus"
      }
    ]
  },
  {
    header: "15+ Access Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta"
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD"
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud"
      },
      {
        icon: office365,
        appName: "Microsoft Office 365"
      },
      {
        icon: onelogin,
        appName: "Onelogin"
      }
    ]
  },

  {
    header: "09+ MDM Apps",
    userApps: [
      {
        icon: intune,
        appName: "Microsoft Intune"
      },
      {
        icon: mobileiron,
        appName: "MobileIron"
      },
      {
        icon: hexnode,
        appName: "Hexnode"
      },
      {
        icon: citrix,
        appName: "Citrix"
      },
      {
        icon: service_desk,
        appName: "ManageEngine MDM"
      }
    ]
  }
];

const expense = require("../../assets/images/landing_page/expense.png");
const policy = require("../../assets/images/landing_page/policy.png");
const offboarding = require("../../assets/images/landing_page/offboarding.png");
const onboarding = require("../../assets/images/landing_page/onboarding.png");
const leave = require("../../assets/images/landing_page/leave.png");
const salary = require("../../assets/images/landing_page/salary.png");
const pto = require("../../assets/images/landing_page/pto.png");

function Section1() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white "
      id="section1"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <h3>Deliver AI Self-service using Conversational AI Chatbot</h3>
            <p>
              {" "}
              Our platform enables you to build chatbots in minutes Adding,
              removing and or training new skills or topics is easy, and no
              coding required. Let employees resolve their own workplace issues
              autonomously without contacting agents.{" "}
            </p>
            <p className="second_p">
              Go AI self-service with Gen AI Chatbot to deliver remote employee support
              – faster, smarter, and easier.
            </p>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              src={section_two}
              alt="Deliver AI Self-service using Conversational AI Chatbot"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
function Section2() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: salary,
      title: "Salary Enquires",
      tabFor: "vertical-tab-one"
    },
    {
      image: leave,
      title: "Apply Leave",
      tabFor: "vertical-tab-two"
    },

    {
      image: expense,
      title: "Expense Claims",
      tabFor: "vertical-tab-three"
    },
    {
      image: policy,
      title: "Policy Searches",
      tabFor: "vertical-tab-four"
    },
    {
      image: onboarding,
      title: "Onboarding",
      tabFor: "vertical-tab-five"
    },
    {
      image: offboarding,
      title: "Offboarding",
      tabFor: "vertical-tab-six"
    }
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("HR Chatbot");

  const listenScrollEvent = e => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <TabProvider defaultTab="vertical-tab-one" vertical>
      <section
        className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white landing_page_chatbot ai_cards_service"
        id="section2"
      >
        <div className="">
          <div className="row">
            <div className="col-md-6 feature_page_content_left">
              <h2>
                Drive efficiency in your IT Helpdesk with employee service
                automation
              </h2>
              <p className="desktop_tab_list">
                Workativ’s one-click integration and out of the box workflows
                with your existing apps will enable true AI self-service
                automation. Automate employee issues & requests such as{" "}
                <>
                  {" "}
                  {buttonMenu.map((menu, i) => (
                    <li className="button_list_li">
                      <span
                        className={isActive === menu.tabName ? "" : ""}
                        onClick={() => {
                          setIsActive(menu.tabName);
                          setOffset(i);
                        }}
                      >
                        {" "}
                        {menu.content && (
                          <span className="pad_content">{menu.content}</span>
                        )}
                        {menu.tabFor1 ? (
                          <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                        ) : null}
                      </span>
                      <span className="space_left"> , </span>
                    </li>
                  ))}
                </>
                exit feedbacks and more.
              </p>
              <p className="mobile_tab_list">
                Workativ’s one-click integration and out of the box workflows
                with your existing apps will enable true AI self-service
                automation. Automate employee issues & requests such as{" "}
                <>
                  {" "}
                  {buttonMenu.map((menu, i) => (
                    <li className="button_list_li">
                      <AnchorLink href={"#" + menu.tabFor1} offset={() => -330}>
                        <span
                          className={isActive === menu.tabName ? "" : ""}
                          onClick={() => {
                            setIsActive(menu.tabName);
                            setOffset(i);
                          }}
                        >
                          {" "}
                          {menu.content && (
                            <span className="pad_content">{menu.content}</span>
                          )}
                          {menu.tabFor1 ? (
                            <Tab tabFor={menu.tabFor1}>{menu.tabName}</Tab>
                          ) : null}
                        </span>
                        <span className="space_left"> , </span>
                      </AnchorLink>
                    </li>
                  ))}
                </>
                exit feedbacks and more.
              </p>
              <p className="second_p">
                Make self-service meaningful. Add workflows so your self-service
                can auto-resolve issues.
              </p>
              <div className="card_link_landing" />
            </div>
            <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
              <div className="desktop_tabs">
                <TabList>
                  {slides.map(menu => (
                    <Tab tabFor={menu.tabFor}>{menu.title}</Tab>
                  ))}
                </TabList>
                {slides.map(menu => (
                  <TabPanel tabId={menu.tabFor}>
                    <img src={menu.image} alt={menu.title}></img>
                  </TabPanel>
                ))}
              </div>
              <div className="slider_wrapper">
                <div
                  className="slider_container"
                  id={slides[offset].tabFor}
                  {...handlers}
                >
                  <img src={slides[offset].image} alt={slides[offset].title} />
                </div>
                <div className="controls_wrapper">
                  <span
                    className="controls_left"
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      decrementOffset();
                    }}
                  >
                    {isSmall ? (
                      <MobNextArrowIcon />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g id="left_arrow" transform="translate(-20 -1622)">
                          <circle
                            id="Ellipse_254"
                            data-name="Ellipse 254"
                            cx="12"
                            cy="12"
                            r="12"
                            transform="translate(20 1622)"
                            fill="#fff"
                          />
                          <g
                            id="Group_6414"
                            data-name="Group 6414"
                            transform="translate(-396 -258)"
                          >
                            <g
                              id="Group_6413"
                              data-name="Group 6413"
                              transform="translate(424 1886)"
                            >
                              <path
                                id="Path_3332"
                                data-name="Path 3332"
                                d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                                transform="translate(-424 -1886)"
                                fill="#2c7357"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    )}
                  </span>
                  <span>{slides[offset].title}</span>
                  <span
                    className="controls_right"
                    onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      incrementOffset();
                    }}
                  >
                    {isSmall ? (
                      <MobNextArrowIcon />
                    ) : (
                      <svg
                        id="right_arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          id="Ellipse_254"
                          data-name="Ellipse 254"
                          cx="12"
                          cy="12"
                          r="12"
                          fill="#fff"
                        />
                        <g
                          id="Group_6414"
                          data-name="Group 6414"
                          transform="translate(8.861 6)"
                        >
                          <g id="Group_6413" data-name="Group 6413">
                            <path
                              id="Path_3332"
                              data-name="Path 3332"
                              d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                              transform="translate(-424 -1886)"
                              fill="#2c7357"
                            />
                          </g>
                        </g>
                      </svg>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TabProvider>
  );
}
function Section3() {
  return (
    <section
      className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_white"
      id="section3"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">
            <h3>Deploy AI Self Service on your Slack or Teams in few clicks</h3>
            <p>
              AI Self Service on Slack or Teams have shown to really improve
              employee experience. Customers have 98% CSAT in employee service
              experience.{" "}
            </p>
            <p>
              Delight your employees with always-on 24/7, follow-me,
              auto-resolution self-service, from the comfort of their favourite
              apps like Slack or Teams.{" "}
            </p>
            <div className="chat_channels_trial">
              <div className="chat_channels_trial_btn">
                <img
                  src={require("../../assets/images/trial_landing/slack.png")}
                  alt="slack"
                />
                Slack
              </div>
              <div className="chat_channels_trial_btn">
                <img
                  src={require("../../assets/images/trial_landing/teams.png")}
                  alt="teams"
                />
                Teams
              </div>
            </div>
          </div>
          <div className="col-md-6 feature_page_img_right ">
            <img
              src={section_four}
              alt="Deploy AI Self Service on your Slack or Teams in few clicks."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const resoucesData = [
  {
    image: blogImg,
    header: "Top 9 Reasons To Build a Digital Workplace – Workativ",
    url:
      "https://blog.workativ.com/top-9-reasons-to-build-a-digital-workplace-workativ/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header: "Leveraging the Value of AI Self-Service – Workativ ",
    url:
      "https://blog.workativ.com/leveraging-the-value-of-ai-self-service-workativ/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: videoImg,
    header:
      "Leapfrog with Digital Assistants that enable business continuity during COVID-19",
    url:
      "https://blog.workativ.com/get-over-the-pandemic-standstill-leapfrog-with-digital-assistants-that-enable-business-continuity-during-covid-19/",
    link: "Read Blog",
    alt: "blog"
  }
];

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "salary enquires"
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "apply leave"
  },

  {
    tabFor1: "vertical-tab-three",
    tabName: "expense claims"
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "policy searches"
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "employee onboarding"
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: "employee offboarding",
    content: "or"
  }
];
